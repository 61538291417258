import { Box, Card, Grid, Typography } from "@mui/material";
import React from "react";

export default function Awards() {
  const cardDetails = [
    {
      num: "5",
      subTittile: "Acers of landfill reduced and landscaped",
    },
    {
      num: "3 Lakh",
      subTittile: "Units we cater",
    },
    {
      num: "2 Lakh",
      subTittile: "Tons of waste handled last year",
    },
  ];
  return (
    <Box id="awards">
      <Box
        sx={{
          background: "#778A2420",
          my: { lg: "120px", xs: "20px" },
          color: "#101828",
          zIndex: 2,
          position: "relative",
        }}
      >
        <Box
          className="layoutMargin"
          sx={{
            my: { lg: "80px 300px", xs: "20px" },
            py: { lg: "100px", xs: "40px" },
          }}
        >
          <Grid container>
            <Grid lg={8} item={true} sx={{ textAlign: "start" }}>
              <Typography
                variant="body1"
                className="caveatFont"
                sx={{
                  fontSize: "16px",
                  fontWeight: "600",
                  color: " #778A24",
                }}
              >
                Accolades
              </Typography>
              <Typography
                variant="h2"
                sx={{
                  mt: 4,
                  fontSize: "56px",
                  textAlign: "start",
                  fontWeight: 500,
                  mt: { lg: "0px", xs: "20px" },
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                Rewards & Recognition
              </Typography>
              <Typography variant="body2" sx={{ margin: "20px 0px" }}>
                SuperStartUp of the Year 2016-17
              </Typography>
              <Typography variant="body2" sx={{ margin: "20px 0px" }}>
                Silicon Company of the Year 2017
              </Typography>
              <Typography variant="body2" sx={{ margin: "20px 0px" }}>
                Recognition from Parivartan- Britt Worldwide India Pvt Ltd
              </Typography>
              <Typography variant="body2" sx={{ margin: "20px 0px" }}>
                Winner of Smart 50 solutions to transform India
              </Typography>
              <Box
                sx={{
                  background: "#778A24",
                  display: "flex",
                  width: "250px",
                  padding: "16px",
                  borderRadius: "7px",
                  justifyContent: "center",
                  color: "#fff",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{ fontWeight: 700, fontSize: "30px" }}
                >
                  6
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: 500, lineHeight: "1", paddingLeft: "20px" }}
                >
                  No of cities we serve
                </Typography>
              </Box>
            </Grid>
            <Grid lg={4} xs={12} item={true} sx={{ pl: { lg: "20px", xs: 0 } }}>
              {cardDetails.map((card, index) => {
                return (
                  <Card
                    key={index}
                    sx={{
                      p: "20px",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "start",
                      flexWrap: "nowrap",
                      width: "100%",
                      mt: "20px",
                    }}
                  >
                    <Box
                      sx={{
                        background: "#778A24",
                        width: "100px",
                        height: "60px",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "7px",
                        color: "#fff",
                      }}
                    >
                      <Box sx={{ width: "100px" }}>
                        <Typography
                          variant="body1"
                          sx={{
                            fontWeight: 700,
                            fontSize: "22px",
                            textAlign: "center",
                          }}
                        >
                          {card.num}
                        </Typography>
                      </Box>
                    </Box>
                    <Box sx={{ ml: "20px", textAlign: "start" }}>
                      <Typography
                        variant="body1"
                        sx={{
                          fontWeight: 400,
                        }}
                      >
                        {card.subTittile}
                      </Typography>
                    </Box>
                  </Card>
                );
              })}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
