import {
  Box,
  Button,
  Grid,
  Link,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import React from "react";
import { FaFacebookF, FaPhone } from "react-icons/fa";
import { BsPinterest, BsTwitter } from "react-icons/bs";
import { FiInstagram } from "react-icons/fi";
import { HiMail } from "react-icons/hi";
import { ImLocation } from "react-icons/im";

function Footer() {
  const socialFooter = [
    {
      icon: <FaFacebookF size={20} />,
      url: "https://www.facebook.com/profile.php?id=100083287460955&ref=pages_you_manage",
    },
    {
      icon: <BsTwitter size={20} />,
      url: "https://twitter.com/ExtraCarbon",
    },
    {
      icon: <FiInstagram size={20} />,
      url: "https://www.instagram.com/extracarbonn/",
    },
    {
      icon: <BsPinterest size={20} />,
      url: "https://in.pinterest.com/extracarbon1279/",
    },
  ];

  const listNav = [
    {
      title: "About",
      url: "#about",
    },
    {
      title: "Our Services",
      url: "#services",
    },
    {
      title: "Awards",
      url: "#awards",
    },
    {
      title: "Contact",
      url: "#contact",
    },
  ];

  const contactInfo = [
    {
      value: "am@greentooth.in",
      icon: <HiMail style={{ marginRight: 4 }} size={20} />,
    },
    {
      value: "+91 92890 97931",
      icon: <FaPhone style={{ marginRight: 4 }} />,
    },
    {
      value:
        "MCG Material Recovery Facility Near Shiksha Bharti Public School, Kadarpur Road, Badshahpur, Gurugram 122101",
      icon: <ImLocation style={{ marginRight: 4 }} size={20} />,
    },
  ];

  const templateDesign = [
    {
      img: "/assets/TransferMSWOps.webp",
      url: "/",
    },
    {
      img: "/assets/MSWsecondaryOps.webp",
      url: "/",
    },
    {
      img: "/assets/mswOps.webp",
      url: "/",
    },
    {
      img: "/assets/msw.webp",
      url: "/",
    },
    {
      img: "/assets/municipalSolidWaste.webp",
      url: "/",
    },
    {
      img: "/assets/wasteProcessing.webp",
      url: "/",
    },
  ];
  return (
    <footer
      style={{
        background: "#1E3226",
        // background: "#fff3fb",
        color: "#fff",
        position: "relative",
      }}
    >
      <Box
        className="layoutMargin"
        sx={{ py: 10, px: { lg: 0, xs: "20px" }, pb: { lg: 15 } }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            lg={4}
            sx={{ pr: 10, textAlign: "start" }}
          >
            <img
              src="/assets/hero-header.webp"
              style={{ width: "200px", marginBottom: "20px" }}
            />
            <Typography
              variant="body1"
              sx={{ color: "#a5b9ad", fontSize: "16px", textAlign: "justify" }}
            >
              Greentooth Technologies Private Limited (GTPL) is an innovative
              organisation which is working towards an agenda of providing a
              sustainable tomorrow & creation of green jobs.
            </Typography>
            <Box sx={{ mt: 4, display: "flex" }}>
              {socialFooter.map((item, index) => {
                return (
                  <Link
                    key={index}
                    href={item.url}
                    sx={{
                      background: "#fff",
                      p: 2,
                      color: "#fff",
                      borderRadius: "7px",
                      cursor: "pointer",
                      mr: 2,
                      display: "flex",
                      alignItems: "center",
                      background: "#006940",

                      "&:hover": {
                        color: "#fff",
                        background: "#00694060",
                      },
                    }}
                  >
                    {item.icon}
                  </Link>
                );
              })}
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            lg={2}
            sx={{ mt: { lg: 0, xs: "40px" } }}
          >
            <Typography
              variant="h6"
              sx={{ fontWeight: 500, textAlign: "start", fontSize: "26px" }}
            >
              Information
            </Typography>
            <List sx={{ p: 0, m: 0, color: "#a5b9ad", fontWeight: 400 }}>
              {listNav.map((nav, index) => {
                return (
                  <ListItem sx={{ p: 0, mt: 2, cursor: "pointer" }} key={index}>
                    <Link
                      href={nav.url}
                      sx={{
                        color: "inherit",
                        textDecoration: "none",
                      }}
                    >
                      <p>{nav.title}</p>
                    </Link>
                  </ListItem>
                );
              })}
            </List>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            lg={3}
            sx={{ mt: { lg: 0, xs: "40px" }, pr: 6 }}
          >
            <Typography
              variant="h6"
              sx={{ fontWeight: 500, textAlign: "start", fontSize: "26px" }}
            >
              Contact
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: "#a5b9ad",
                fontSize: "16px",
                my: 2,
                textAlign: "left",
                fontWeight: 400,
              }}
            >
              Would you have any enquiries.Please feel free to contuct us
            </Typography>
            {contactInfo.map((item, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    color: "#a5b9ad",
                    fontSize: "16px",
                    my: 2,
                    display: "flex",
                    alignItems: "flex-start",
                    textAlign: "start",
                    fontWeight: 400,
                  }}
                >
                  <Box sx={{ mr: "5px" }}>{item.icon}</Box>
                  <Typography variant="body1" sx={{ textAlign: "left" }}>
                    {item.value}
                  </Typography>
                </Box>
              );
            })}
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            lg={3}
            sx={{ mt: { lg: 0, xs: "40px" }, pl: { lg: 4, xs: 0 } }}
          >
            <Typography
              variant="h6"
              sx={{ fontWeight: 500, textAlign: "start", fontSize: "26px" }}
            >
              Our Work
            </Typography>
            <Box sx={{ display: "flex", flexWrap: "wrap", mt: 2 }}>
              {templateDesign.map((listOfImg, index) => {
                return (
                  <Box
                    sx={{
                      flex: "1 1 30%",
                      display: "flex",
                      mt: 2,
                    }}
                    key={index}
                  >
                    <img
                      className="imgHover"
                      alt="img"
                      width={1000}
                      height={1000}
                      src={listOfImg.img}
                      style={{
                        height: "80px",
                        width: "80px",
                        borderRadius: "7px",
                      }}
                    />
                  </Box>
                );
              })}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <img
        alt="img"
        width={1000}
        height={1000}
        src="/assets/whitepaperbottom.svg"
        className="bottomPaper"
        style={{
          position: "absolute",
          zIndex: 4,
          bottom: -60,
          left: 0,
          width: "100%",
          height: "auto",
        }}
      />
    </footer>
  );
}

export default Footer;
