import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import OverviewCard from "./OverviewCard";

function OverviewPercSection({ currentDayData, prevDayData }) {
  const [pieData, setPieData] = useState({});
  const [currentDataLength, setCurrentDataLength] = useState(0);
  const [loadingPerc, setLoadingPerc] = useState(true);

  // Function to calculate pie chart data for a specific key
  const calculatePieData = (data, key) => {
    const valueTrue = data.filter((item) => item[key]).length;
    const valueFalse = data.length - valueTrue;
    return [valueTrue, valueFalse];
  };

  const info = [
    {
      id: 1,
      name: "Electricity",
      image: "/assets/electricicon.png",
    },
    {
      id: 2,
      name: "Soap",
      image: "/assets/soap.png",
    },
    {
      id: 3,
      name: "Water",
      image: "/assets/waterGreentooth.png",
    },
    {
      id: 4,
      name: "Clean Premises",
      image: "/assets/preMises.png",
    },
    {
      id: 5,
      name: "Clean Seat",
      image: "/assets/toitletseat.png",
    },
  ];

  useEffect(() => {
    if (currentDayData) {
      // Calculate pie chart data for each category
      const electricityData = calculatePieData(currentDayData, "electricity");
      const soapData = calculatePieData(currentDayData, "soap");
      const waterData = calculatePieData(currentDayData, "water");
      const toiletPremisesData = calculatePieData(
        currentDayData,
        "cleanPremises"
      );
      const toiletSeatsData = calculatePieData(currentDayData, "cleanSeat");

      setPieData({
        electricity: electricityData,
        soap: soapData,
        water: waterData,
        toiletPremises: toiletPremisesData,
        toiletSeats: toiletSeatsData,
      });

      setCurrentDataLength(currentDayData.length);
      setLoadingPerc(false);
    }
  }, [currentDayData]);

  return (
    <Box>
      {!loadingPerc ? (
        <Box
          sx={{
            display: "flex",
            gap: 2,
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          {Object.keys(pieData).map((category, index) => (
            <Box key={index} lg={3} sx={{ flexGrow: 1 }}>
              <OverviewCard
                title={info[index].name}
                img={info[index].image}
                currentData={pieData[category]}
                currentDataLength={currentDataLength}
                // prevData={prevPieData[category]}
              />
            </Box>
          ))}
        </Box>
      ) : (
        <Box>Loading...</Box>
      )}
    </Box>
  );
}

export default OverviewPercSection;
