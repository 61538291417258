import { Box, Typography } from "@mui/material";
import React from "react";

function BottomBar() {
  return (
    <Box
      className="layoutMargin"
      sx={{
        textAlign: "center",
        py: 2,
        zIndex: 5,
        position: "relative",
        color: "#000",
        display: "flex",
        flexDirection: { lg: "row", xs: "column" },
        justifyContent: "space-between",
      }}
    >
      <div>© 2023 GTPL. All Rights Reserved.</div>
      <div>
        <a href="https://www.augmetic.co.uk/" style={{ color: "#000" }}>
          Design & Developed by{" "}
          <span style={{ color: "#1E3226" }}>
            <b>Augmetic</b>
          </span>
        </a>
      </div>
    </Box>
  );
}

export default BottomBar;
