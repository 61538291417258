import { Container } from "@mui/system";
import React, { useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function ThankYou() {
  const navigate = useNavigate();

  const redirectPage = () => {
    navigate("/");
  };

  return (
    <Container>
      <Box
        sx={{
          display: "flex",
          height: "80vh",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "40px",
        }}
      >
        <img src="/assets/thanks.png" />
        <Typography
          variant="h2"
          sx={{ fontSize: "26px", fontWeight: "600", color: "#101828" }}
        >
          Thank you for your feedbacks
        </Typography>
        <Button
          onClick={redirectPage}
          sx={{
            fontSize: "14px",
            color: "#778A24",
            background: "transparent",
            ["&:hover"]: { background: "transparent" },
            fontWeight: 700,
            p: 0,
          }}
        >
          Go to Home Page
        </Button>
      </Box>
    </Container>
  );
}
