import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { Toaster, toast } from "react-hot-toast";

function DashboardLogin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const auth = getAuth();
  const handleSubmit = () => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        localStorage.setItem("admin greentooth", user.accessToken);
        toast.success("Successfully login");
        window.location = "/dashboard";
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        toast.error("Make Sure email and password is correct");

        // ..
      });
  };
  return (
    <Box
      sx={{
        backgroundImage:
          "url('/assets/person-doing-community-service-by-collecting-trash.webp')",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Toaster />

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Box
          sx={{
            width: "400px",
            border: "1px solid #778A24",
            p: 4,
            borderRadius: "10px",
            background: "#fff",
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: 500 }}>
            Login
          </Typography>
          <TextField
            placeholder="Email address"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{
              background: "#778A2420",
              width: "100%",
              mt: 4,
              "&::focus": {
                border: "1px solid green",
              },
            }}
          />
          <TextField
            placeholder="Password"
            type="password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            sx={{
              background: "#778A2420",
              width: "100%",
              mt: 4,
              "&::focus": {
                border: "1px solid green",
              },
            }}
          />
          <Button
            onClick={handleSubmit}
            sx={{
              background: "#778A24",
              color: "#fff",
              border: 0,
              p: "7px 30px",
              width: "100%",
              mt: 4,
              "&:hover": {
                background: "#778A2490",
              },
            }}
          >
            Login
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default DashboardLogin;
