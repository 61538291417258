import React, { useRef, useState } from "react";
import FeedbackCard from "./FeedbackCard";
import { Box, Button } from "@mui/material";
import { db } from "../../firebase";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";

function FeedbacksSection() {
  const location = useLocation();
  const feedbackSet = collection(db, "feedbacks");
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    userName: "",
    phone: "",
    message: "",
    electricityRating: "",
    waterRating: "",
    soapRating: "",
    toiletPremisesRating: "",
    toiletSeatRating: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => {
      return {
        ...prevData,
        [name]: value,
      };
    });
  };

  const submitFeedback = () => {
    const date = moment(new Date()).unix();
    const currentDate = moment().format("LLL");
    const locationUrl = location.pathname.split("/")[2];
    addDoc(feedbackSet, {
      ...formData,
      location: locationUrl,
      createdAt: date,
      createdAtString: String(currentDate),
    })
      .then((docRef) => {
        navigate("/thank-you");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Box sx={{ marginTop: "100px", marginBottom: "100px" }}>
      <Box sx={{ position: "relative" }}>
        <Box className="container d-flex justify-content-center">
          <Box className="card mt-5 pb-5">
            <Box className="p-4 ">
              <h1 className="mb-0">Your feedback help us to improve.</h1>
              <hr></hr>
              <FeedbackCard
                ques="On a scale of 1 to 5, how would you rate the availability of electricity ?"
                quesNum="1"
                setFormData={setFormData}
              />
              <FeedbackCard
                ques="On a scale of 1 to 5, how would you rate the availability of water?"
                quesNum="2"
                setFormData={setFormData}
              />
              <FeedbackCard
                ques="On a scale of 1 to 5, how would you rate the availability of soap?"
                quesNum="3"
                setFormData={setFormData}
              />
              <FeedbackCard
                ques="On a scale of 1 to 5, how would you rate the cleanliness of toilet premises?"
                quesNum="4"
                setFormData={setFormData}
              />
              <FeedbackCard
                ques="On a scale of 1 to 5, how would you rate the cleanliness of the toilet seat?"
                quesNum="5"
                setFormData={setFormData}
              />

              <Box className="form-group mt-4">
                <input
                  type="name"
                  name="userName"
                  className="form-control"
                  value={formData.userName}
                  placeholder="Name"
                  onChange={handleChange}
                />
              </Box>

              <Box className="form-group mt-4">
                <input
                  type="number"
                  name="phone"
                  className="form-control"
                  placeholder="phone"
                  value={formData.phone}
                  onChange={handleChange}
                />
              </Box>

              <Box className="form-group mt-4">
                <textarea
                  name="message"
                  className="form-control"
                  rows="4"
                  placeholder="Message"
                  value={formData.message}
                  onChange={handleChange}
                ></textarea>
              </Box>
              <Box className="mt-2">
                <Button
                  disabled={
                    !formData.phone ||
                    !formData.userName ||
                    !formData.electricityRating ||
                    !formData.waterRating ||
                    !formData.soapRating ||
                    !formData.toiletPremisesRating ||
                    !formData.toiletSeatRating
                  }
                  onClick={submitFeedback}
                  sx={{
                    background: "#778A24",
                    color: "#fff",
                    border: 0,
                    p: "7px 30px",
                    width: "100%",
                    mt: 4,
                    "&:hover": {
                      background: "#778A2490",
                    },
                  }}
                >
                  Send feedback
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default FeedbacksSection;
