import {
  Box,
  Button,
  Card,
  Dialog,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import OverviewCard from "../common/OverviewCard";
import PieCard from "../common/PieCard";
import { DateRangePicker } from "react-date-range";
import { addDays, set } from "date-fns";
import {
  collection,
  endBefore,
  getDocs,
  limit,
  limitToLast,
  orderBy,
  query,
  startAfter,
  startAt,
  where,
} from "firebase/firestore";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { db } from "../../firebase";
import UploadLocation from "./UploadLocation";
import moment from "moment";
import OverviewPercSection from "../common/OverviewPercSection";
import { useRef } from "react";
import Pdf from "react-to-pdf";
import { Toaster, toast } from "react-hot-toast";
import ImagePreview from "../common/ImagePreview";
import CancelIcon from "@mui/icons-material/Cancel";

export const YesNoBottom = () => {
  return (
    <Box
      sx={{
        display: "flex",
        gap: 2,
        justifyContent: "center",
      }}
    >
      <Box sx={{ display: "flex", gap: 2 }}>
        <Box
          sx={{
            width: "40px",
            height: "30px",
            background: "#F5B849",
            borderRadius: "5px",
          }}
        ></Box>
        <Typography sx={{ fontWeight: 500 }}>Yes</Typography>
      </Box>
      <Box sx={{ display: "flex", gap: 2 }}>
        <Box
          sx={{
            width: "40px",
            height: "30px",
            background: "#006940",
            borderRadius: "5px",
          }}
        ></Box>
        <Typography sx={{ fontWeight: 500 }}>No</Typography>
      </Box>
    </Box>
  );
};

function Overview({ allLocation }) {
  const [location, setLocation] = useState("AllLocation");
  const [locationName, setLocationName] = useState("All Location");
  const [displayDate, setDisplayDate] = useState();
  const [showCalender, setShowCalender] = useState(false);

  const [surveyData, setSurveyData] = useState([]);
  const [cardData, setCardData] = useState([]);

  const [imageData, setImageData] = useState();
  const [pieElectricity, setPieElectricity] = useState();
  const [pieSoap, setPieSoap] = useState();
  const [pieWater, setPieWater] = useState();
  const [pieToiletPremises, setPieToiletPremises] = useState();
  const [pieToiletSeat, setPieToiletSeat] = useState();

  const [currentDayData, setCurrentDayData] = useState();
  const [prevDayData, setPrevDayData] = useState();

  const [unixStart, setUnixStart] = useState();
  const [unixEnd, setUnixEnd] = useState();

  const ref = useRef();

  const options = {
    orientation: "portrait",
    unit: "in",
    format: [9, 11],
  };

  const [state, setState] = useState({
    selection1: {
      startDate: addDays(new Date(), 0),
      endDate: addDays(new Date(), 0),
      key: "selection1",
      dateDivide: [],
    },
  });

  const handleLocation = (e) => {
    const loc = e.target.value;
    setShowCalender(false);

    const locationName = allLocation.filter(
      (item) => item.id === e.target.value
    );
    setLocationName(
      locationName.length !== 0 ? locationName[0].location : "All Location"
    );

    let endofDay = moment(state?.selection1?.endDate).endOf("day").toString();
    let startofDay = moment(state?.selection1?.startDate)
      .startOf("day")
      .toString();

    let unixStartDate = moment(startofDay).unix() + "000";
    let unixEndDate = moment(endofDay).unix() + "000";
    setLocation(loc);
    let surveySetloc =
      loc === "AllLocation"
        ? query(
            collection(db, "survey"),
            where("createdOn", ">=", Number(unixStartDate)),
            where("createdOn", "<=", Number(unixEndDate))
          )
        : query(
            collection(db, "survey"),
            where("id", "==", String(loc)),
            where("createdOn", ">=", Number(unixStartDate)),
            where("createdOn", "<=", Number(unixEndDate))
          );
    getSurveyData(surveySetloc);
  };

  function handleCalendarChange(item) {
    setState({
      ...state,
      selection1: {
        startDate: item?.selection1?.startDate,
        endDate: item?.selection1?.endDate,
        key: "selection1",
      },
    });
  }

  const handleCalendarApply = () => {
    setShowCalender(false);
    let endofDay = moment(state?.selection1?.endDate).endOf("day").toString();
    let unixStartDate = moment(state?.selection1?.startDate).unix() + "000";
    let unixEndDate = moment(endofDay).unix() + "000";

    let surevySetDate =
      location === "AllLocation"
        ? query(
            collection(db, "survey"),
            where("createdOn", ">=", Number(unixStartDate)),
            where("createdOn", "<=", Number(unixEndDate))
          )
        : query(
            collection(db, "survey"),
            where("id", "==", String(location)),
            where("createdOn", ">=", Number(unixStartDate)),
            where("createdOn", "<=", Number(unixEndDate))
          );
    getSurveyData(surevySetDate);
  };

  let surveySet = query(
    collection(db, "survey"),
    // where("location", "==", location),
    orderBy("createdOn", "desc")
  );

  const handleImgToShow = (data) => {
    data[0] &&
      setImageData({
        img: JSON.parse(data[data.length - 1].photos),
        time: data[data.length - 1].createdOnString,
      });
  };

  const electricPieData = (data) => {
    let valueTrue = 0;
    let valueFalse = 0;
    const eletricData = data.map((item) => {
      if (item.electricity) {
        valueTrue++;
      } else {
        valueFalse++;
      }
    });
    setPieElectricity([valueTrue, valueFalse]);
  };

  const soapPieData = (data) => {
    let valueTrue = 0;
    let valueFalse = 0;
    const soapData = data.map((item) => {
      if (item.soap) {
        valueTrue++;
      } else {
        valueFalse++;
      }
    });
    setPieSoap([valueTrue, valueFalse]);
  };

  const waterPieData = (data) => {
    let valueTrue = 0;
    let valueFalse = 0;
    const waterData = data.map((item) => {
      if (item.water) {
        valueTrue++;
      } else {
        valueFalse++;
      }
    });
    setPieWater([valueTrue, valueFalse]);
  };

  const toiletPremisesPieData = (data) => {
    let valueTrue = 0;
    let valueFalse = 0;
    const toiletPremisesData = data.map((item) => {
      if (item.cleanPremises) {
        valueTrue++;
      } else {
        valueFalse++;
      }
    });
    setPieToiletPremises([valueTrue, valueFalse]);
  };

  const toiletSeatsPieData = (data) => {
    let valueTrue = 0;
    let valueFalse = 0;
    const toiletSeatsData = data.map((item) => {
      if (item.cleanSeat) {
        valueTrue++;
      } else {
        valueFalse++;
      }
    });
    setPieToiletSeat([valueTrue, valueFalse]);
  };

  const getSurveyData = async (surveySet) => {
    getDocs(surveySet)
      .then((res) => {
        return res.docs.map((item) => {
          return {
            ...item.data(),
          };
        });
      })
      .then((data) => {
        setSurveyData(data);
        handleImgToShow(data);
        electricPieData(data);
        soapPieData(data);
        waterPieData(data);
        toiletPremisesPieData(data);
        toiletSeatsPieData(data);
      });
    // const documentSnapshots = await getDocs(surveySet);
    // const lastVisiblea =
    //   documentSnapshots.docs[documentSnapshots.docs.length - 1];
    // lastVisiblea && setLastVisible(lastVisiblea);

    // const prevVisiblea = documentSnapshots.docs[0];
    // prevVisiblea && setLastVisible(prevVisiblea);
  };

  // useEffect(() => {
  //   let endofDay = moment(state?.selection1?.endDate).endOf("day").toString();
  //   let unixStartDate = moment(state?.selection1?.startDate).unix() + "000";
  //   let unixEndDate = moment(endofDay).unix() + "000";

  //   setUnixStart(unixStartDate);
  //   setUnixEnd(unixEndDate);
  // }, [state]);

  useEffect(() => {
    let startofDay = moment(new Date()).startOf("day").toString();
    let unixStartDate = moment(startofDay).unix() + "000";

    let surevyFirstTime = query(
      collection(db, "survey"),
      where("createdOn", ">=", Number(unixStartDate))
    );
    getSurveyData(surevyFirstTime);
  }, []);

  useEffect(() => {
    let startofDay = moment(new Date()).startOf("day").toString();
    let unixStartDate = moment(startofDay).unix() + "000";

    let surevyCardSet = query(
      collection(db, "survey"),
      where("createdOn", ">=", Number(unixStartDate))
    );
    getDocs(surevyCardSet)
      .then((res) => {
        return res.docs.map((item) => {
          return {
            ...item.data(),
          };
        });
      })
      .then((data) => {
        setCardData(data);
      });
  }, []);

  useEffect(() => {
    const dateDivideStart = state?.selection1?.startDate?.toString().split(" ");
    const dateDivideEnd = state?.selection1?.endDate?.toString().split(" ");
    dateDivideStart &&
      dateDivideEnd &&
      setDisplayDate(
        `${dateDivideStart[2]} ${dateDivideStart[1]} - ${dateDivideEnd[2]}`
      );
  }, [state]);

  useEffect(() => {
    const date = new Date();
    let currentDayStart = moment(date).startOf("day").toString();
    let currentDayEnd = moment(date).endOf("day").toString();
    let unixcurrentDayStart = moment(currentDayStart).unix() + "000";
    let unixcurrentDayEnd = moment(currentDayEnd).unix() + "000";

    let previousDayStart = moment(date.setDate(date.getDate() - 1))
      .startOf("day")
      .toString();
    let previousDayEnd = moment(date.setDate(date.getDate()))
      .endOf("day")
      .toString();
    let unixpreviousDayStart = moment(previousDayStart).unix() + "000";
    let unixpreviousDayEnd = moment(previousDayEnd).unix() + "000";

    let surevyCurrent = query(
      collection(db, "survey"),
      where("createdOn", ">=", Number(unixcurrentDayStart)),
      where("createdOn", "<=", Number(unixcurrentDayEnd))
    );

    let surevyPrevious = query(
      collection(db, "survey"),
      where("createdOn", ">=", Number(unixpreviousDayStart)),
      where("createdOn", "<=", Number(unixpreviousDayEnd))
    );
    getDocs(surevyCurrent).then((res) => {
      setCurrentDayData(
        res.docs.map((item) => {
          return {
            ...item.data(),
          };
        })
      );
    });

    getDocs(surevyPrevious).then((res) => {
      setPrevDayData(
        res.docs.map((item) => {
          return {
            ...item.data(),
          };
        })
      );
    });
  }, []);

  const [open, setOpen] = useState(false);
  const [singleImg, setSingleImg] = useState(null);
  const handleOpen = (img) => {
    setSingleImg(img);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  return (
    <Box sx={{ py: 4 }}>
      <Toaster />
      <Box sx={{ px: 4 }}>
        <OverviewPercSection
          currentDayData={currentDayData}
          // prevDayData={prevDayData}
        />
      </Box>
      <Box
        sx={{
          pt: 10,
          pb: 4,
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          px: 4,
        }}
      >
        <Typography variant="h4">Supervisor Data</Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: 2,
            mt: 1,
            "& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input": {
              padding: "10px 38px 10px 10px",
            },
          }}
        >
          <Box>
            <Select name="location" value={location} onChange={handleLocation}>
              <MenuItem value="AllLocation">All Location</MenuItem>
              {allLocation.map((item, index) => {
                return (
                  <MenuItem value={item.id} key={item.id}>
                    {item.location}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
          {/* <Calendar date={new Date()} onChange={handleSelect} /> */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              position: "relative",
            }}
          >
            <Button
              sx={{
                background: "#778A24",
                color: "#fff",
                border: 0,
                p: "7px 30px",
                width: "100%",
                "&:hover": {
                  background: "#778A2490",
                },
              }}
              onClick={() => setShowCalender((prevState) => !prevState)}
            >
              {displayDate}
            </Button>
            {showCalender && (
              <Box
                sx={{
                  position: "absolute",
                  left: "-420px",
                  top: "40px",
                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                  backgroundColor: "#fff",
                  zIndex: 100,
                }}
              >
                <DateRangePicker
                  onChange={(item) => {
                    handleCalendarChange(item);
                  }}
                  // moveRangeOnFirstSelection={false}
                  // retainEndDateOnFirstSelection={false}
                  maxDate={new Date()}
                  ranges={[state.selection1]}
                  rangeColors={["#1E3226"]}
                />
                <Box sx={{ backgroundColor: "#fff", position: "relative" }}>
                  <Button
                    sx={{
                      float: "right",
                      backgroundColor: "#006940",
                      color: "#fff",
                      p: "5px 24px",
                      mx: 2,
                      mb: 1,
                    }}
                    onClick={handleCalendarApply}
                  >
                    Apply
                  </Button>
                  <Button
                    sx={{
                      float: "right",
                      border: "1px solid #006940",
                      color: "#006940",
                      p: "5px 24px",
                      mx: 0,
                      mb: 1,
                    }}
                    onClick={() => {
                      setShowCalender(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            )}
          </Box>

          <Pdf targetRef={ref} options={options} scale={0.7} filename={`test`}>
            {({ toPdf }) => (
              <Button
                onClick={() => {
                  toPdf();
                  toast("Generating pdf");
                }}
                sx={{
                  background: "#006940",
                  color: "#fff",
                  border: 0,
                  p: "7px 30px",
                  "&:hover": {
                    background: "#00694080",
                  },
                }}
              >
                {" "}
                Export Data
              </Button>
            )}
          </Pdf>
        </Box>
      </Box>
      {surveyData.length !== 0 ? (
        <Box sx={{ px: 4 }}>
          <hr></hr>
          {surveyData && (
            <Box
              sx={{
                display: "flex",
                gap: 4,
                py: "30px",
                flexWrap: "wrap",
                justifyContent: "space-between",
                my: 2,
              }}
            >
              {imageData?.img.slice(0, 4).map((item, index) => {
                return (
                  <Box
                    onClick={() =>
                      handleOpen(
                        `https://firebasestorage.googleapis.com/v0/b/wastecollectionproject-eecbb.appspot.com/o/` +
                          encodeURIComponent(item.slice(49)) +
                          "?alt=media&token=152fd981-c0ff-4ed0-93d1-bd3899e8e364"
                      )
                    }
                    key={index}
                    sx={{ display: "flex", flexDirection: "column" }}
                  >
                    <img
                      src={
                        `https://firebasestorage.googleapis.com/v0/b/wastecollectionproject-eecbb.appspot.com/o/` +
                        encodeURIComponent(item.slice(49)) +
                        "?alt=media&token=152fd981-c0ff-4ed0-93d1-bd3899e8e364"
                      }
                      alt="image"
                      style={{
                        width: "250px",
                        height: "250px",
                        objectFit: "cover",
                        flexGrow: 1,
                        borderRadius: "10px",
                      }}
                    />
                    <Typography variant="span" sx={{ fontSize: "15px" }}>
                      {imageData.time}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          )}
          <Box sx={{ position: "relative", zIndex: 1 }}>
            <Grid container spacing={4}>
              <Grid item lg={4} md={6}>
                <Card
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: "10px",
                    p: 4,
                    zIndex: 1,
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{ textAlign: "center", fontWeight: 500 }}
                  >
                    Electricity
                  </Typography>
                  <PieCard pieData={pieElectricity} />
                  <YesNoBottom />
                </Card>
              </Grid>
              <Grid item lg={4} md={6}>
                <Card
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: "10px",
                    p: 4,
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      textAlign: "center",
                      fontWeight: 500,
                      zIndex: 2,
                      position: "relative",
                    }}
                  >
                    Soap
                  </Typography>
                  <PieCard pieData={pieSoap} />
                  <YesNoBottom />
                </Card>
              </Grid>
              <Grid item lg={4} md={6}>
                <Card
                  sx={{ backgroundColor: "#fff", borderRadius: "10px", p: 4 }}
                >
                  <Typography
                    variant="h5"
                    sx={{ textAlign: "center", fontWeight: 500 }}
                  >
                    Toilet Premises
                  </Typography>
                  <PieCard pieData={pieToiletPremises} />
                  <YesNoBottom />
                </Card>
              </Grid>
              <Grid item lg={4} md={6}>
                <Card
                  sx={{ backgroundColor: "#fff", borderRadius: "10px", p: 4 }}
                >
                  <Typography
                    variant="h5"
                    sx={{ textAlign: "center", fontWeight: 500 }}
                  >
                    Toilet Seat
                  </Typography>
                  <PieCard pieData={pieToiletSeat} />
                  <YesNoBottom />
                </Card>
              </Grid>
              <Grid item lg={4} md={6}>
                <Card
                  sx={{ backgroundColor: "#fff", borderRadius: "10px", p: 4 }}
                >
                  <Typography
                    variant="h5"
                    sx={{ textAlign: "center", fontWeight: 500 }}
                  >
                    Water
                  </Typography>
                  <PieCard pieData={pieWater} />
                  <YesNoBottom />
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Box>
      ) : (
        <Box>
          <Typography
            variant="h5"
            sx={{ fontWeight: 500, textAlign: "center", p: 10 }}
          >
            No Data Found
          </Typography>
        </Box>
      )}

      {/* This is for pdf */}
      <Box
        sx={{
          opacity: 0,
          width: "0px",
          height: "0px",
          position: "absolute",
          left: 1000,
          top: 0,
          zIndex: -1,
        }}
      >
        <Box ref={ref} sx={{ px: 4, width: "1200px", height: "1500px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <img
              src="/assets/hero-header.png"
              className="logoHome"
              style={{ width: "150px" }}
            />
            <Box
              sx={{
                display: "flex",
                gap: 4,
              }}
            >
              <Typography variant="body2">Location: {locationName}</Typography>
              <Typography variant="body2">Date: {displayDate}</Typography>
            </Box>
          </Box>
          <hr></hr>
          {surveyData && (
            <Box
              sx={{
                display: "flex",
                gap: 4,
                py: "30px",
                flexWrap: "wrap",
                justifyContent: "space-between",
                my: 2,
              }}
            >
              {imageData?.img.slice(0, 4).map((item, index) => {
                return (
                  <Box
                    key={index}
                    sx={{ display: "flex", flexDirection: "column" }}
                  >
                    <img
                      src={
                        `https://firebasestorage.googleapis.com/v0/b/wastecollectionproject-eecbb.appspot.com/o/` +
                        encodeURIComponent(item.slice(49)) +
                        "?alt=media&token=152fd981-c0ff-4ed0-93d1-bd3899e8e364"
                      }
                      alt="image"
                      style={{
                        width: "250px",
                        height: "250px",
                        objectFit: "cover",
                        flexGrow: 1,
                        borderRadius: "10px",
                      }}
                    />
                    <Typography variant="span" sx={{ fontSize: "15px" }}>
                      {imageData.time}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          )}
          <Box sx={{ position: "relative", zIndex: 1 }}>
            <Grid container spacing={4}>
              <Grid item lg={4} md={6}>
                <Card
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: "10px",
                    p: 4,
                    zIndex: 1,
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{ textAlign: "center", fontWeight: 500 }}
                  >
                    Electricity
                  </Typography>
                  <PieCard pieData={pieElectricity} />
                  <YesNoBottom />
                </Card>
              </Grid>
              <Grid item lg={4} md={6}>
                <Card
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: "10px",
                    p: 4,
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      textAlign: "center",
                      fontWeight: 500,
                      zIndex: 2,
                      position: "relative",
                    }}
                  >
                    Soap
                  </Typography>
                  <PieCard pieData={pieSoap} />
                  <YesNoBottom />
                </Card>
              </Grid>
              <Grid item lg={4} md={6}>
                <Card
                  sx={{ backgroundColor: "#fff", borderRadius: "10px", p: 4 }}
                >
                  <Typography
                    variant="h5"
                    sx={{ textAlign: "center", fontWeight: 500 }}
                  >
                    Toilet Premises
                  </Typography>
                  <PieCard pieData={pieToiletPremises} />
                  <YesNoBottom />
                </Card>
              </Grid>
              <Grid item lg={4} md={6}>
                <Card
                  sx={{ backgroundColor: "#fff", borderRadius: "10px", p: 4 }}
                >
                  <Typography
                    variant="h5"
                    sx={{ textAlign: "center", fontWeight: 500 }}
                  >
                    Toilet Seat
                  </Typography>
                  <PieCard pieData={pieToiletSeat} />
                  <YesNoBottom />
                </Card>
              </Grid>
              <Grid item lg={4} md={6}>
                <Card
                  sx={{ backgroundColor: "#fff", borderRadius: "10px", p: 4 }}
                >
                  <Typography
                    variant="h5"
                    sx={{ textAlign: "center", fontWeight: 500 }}
                  >
                    Water
                  </Typography>
                  <PieCard pieData={pieWater} />
                  <YesNoBottom />
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <Dialog open={open} onClose={handleClose} maxWidth="2xl">
        <Box sx={{ p: 4, background: "#fff", position: "relative" }}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ position: "absolute", right: 20, top: 10 }}
          >
            <CancelIcon sx={{ fontSize: "30px" }} />
          </IconButton>

          <Box sx={{ display: "flex", gap: 4, mt: 2 }}>
            <img
              src={singleImg}
              style={{
                width: "400px",
                height: "600px",
                objectFit: "cover",
                borderRadius: "10px",
              }}
            />
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
}

export default Overview;
