import { Box, Grid, List, ListItem, Typography } from "@mui/material";
import React from "react";

function About() {
  // const listData = [
  //   "Metus interdum metus",
  //   "Ligula cur maecenas",
  //   "Fringilla nullan",
  //   "Metus interdum metus",
  //   "Ligula cur maecenas",
  //   "Fringilla nullan",
  // ];
  return (
    <section id="about">
      <Typography
        variant="h2"
        className="caveatFont"
        sx={{ color: "#778A24", mt: 10, textAlign: "center" }}
      >
        About Us
      </Typography>

      <Typography variant="body1" sx={{ textAlign: "center" }}>
        About Greentooth Technologies Private Limited (GTPL)
      </Typography>

      <Grid
        container
        className="layoutMargin"
        sx={{ my: 10, px: { lg: 0, xs: "20px" } }}
      >
        <Grid lg={6} item>
          <Box
            sx={{ minHeight: "350px", height: "100%", position: "relative" }}
          >
            <img
              src="/assets/person-doing-community-service-by-collecting-trash.webp"
              alt="about us"
              width={1000}
              height={1000}
              className="imgResAbout"
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "7px",
                objectFit: "cover",
                position: "relative",
                zIndex: 2,
              }}
            />
            <Box
              sx={{
                width: "100px",
                height: "200px",
                position: "absolute",
                background: "#778A24",
                top: "20px",
                right: "-10px",
                borderRadius: "7px",

                zIndex: 1,
              }}
            ></Box>
          </Box>
        </Grid>
        <Grid
          lg={6}
          item={true}
          sx={{
            paddingLeft: { lg: "80px", xs: 0 },
            position: "relative",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
            height: "auto",
            textAlign: "start",
          }}
        >
          <Typography variant="h3">
            Greentooth Technologies Private Limited (GTPL)
          </Typography>
          <Typography
            variant="body2"
            sx={{ margin: "20px 0px", textAlign: "justify" }}
          >
            Greentooth Technologies Private Limited (GTPL) is an innovative
            organisation which is working towards an agenda of providing a
            sustainable tomorrow & creation of green jobs. Extracarbon is a
            brand of Greentooth Technologies Private Limited which door to door
            services of Municipal Solid Waste (MSW) collection. Extracarbon was
            launched because we saw the need for a place where the local
            community within a city could come together and take everyday
            actions to choose a right kind of future for them. We started our
            operation in June 2013 from Gurgaon & today we have 300,000 users
            across six cities.
          </Typography>
          <List style={{ columns: 2 }}>
            {/* {listData.map((list, index) => {
              return (
                <ListItem
                  key={index}
                  sx={{
                    background: "transparent",
                    color: "#101828",
                    padding: "8px 0px",
                  }}
                >
                  <img
                    src="/assets/icons8-checkmark.svg"
                    alt="tick"
                    width={20}
                    height={20}
                    style={{
                      width: "20px",
                      height: "20px",
                      marginRight: "10px",
                    }}
                  />

                  {list}
                </ListItem>
              );
            })} */}
          </List>
        </Grid>
      </Grid>
    </section>
  );
}

export default About;
