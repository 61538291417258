import { Box, List, ListItem, Typography } from "@mui/material";
import React from "react";
import { LuLayoutDashboard } from "react-icons/lu";
import { VscFeedback } from "react-icons/vsc";
import { BsClipboardData } from "react-icons/bs";

function Sidenav({ setActive, active }) {
  const sidenavItems = [
    {
      title: "Overview",
      icon: <LuLayoutDashboard style={{ marginRight: "10px" }} />,
    },
    {
      title: "Supervisor",
      icon: <BsClipboardData style={{ marginRight: "10px" }} />,
    },
    {
      title: "Feedbacks",
      icon: <VscFeedback style={{ marginRight: "10px" }} />,
    },
  ];
  return (
    <Box>
      <img src="/assets/hero-header.png" alt="" style={{ width: "80%" }} />

      <Box>
        <List>
          {sidenavItems.map((item, index) => {
            return (
              <ListItem
                onClick={() => setActive(item.title.toLowerCase())}
                key={index}
                sx={{
                  background: "#006940",
                  color: "#fff",
                  fontWeight: 500,
                  borderRadius: 1,
                  padding: "10px 20px",
                  mt: 2,
                }}
              >
                {item.icon} {item.title}
              </ListItem>
            );
          })}
        </List>
      </Box>
      <Box sx={{ position: "absolute", bottom: 20 }}>
        <Typography variant="body2" sx={{ color: "#ffffff60", mt: 2 }}>
          Powered By{" "}
          <a href="https://www.augmetic.co.uk/" style={{ color: "#ffffff" }}>
            Augmetic
          </a>
        </Typography>
      </Box>
    </Box>
  );
}

export default Sidenav;
