import React, { useState } from "react";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { Autoplay, Grid, Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/bundle";

function ImageSlider({ imagesData }) {
  const [step, setStep] = useState(0);
  return (
    <Swiper
      spaceBetween={0}
      initialSlide={step}
      loop={false}
      grid={{
        rows: 1,
        fill: "row",
      }}
      className="mySwiper"
      breakpoints={{
        640: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
        768: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
      }}
      modules={[Grid, Navigation, Pagination, Autoplay]}
      pagination={{
        el: ".custom-pagination",
        clickable: true,
      }}
      navigation={{
        prevEl: ".swiper-button-prev",
        nextEl: ".swiper-button-next",
      }}
      autoplay={{
        delay: 25000,
        disableOnInteraction: false,
      }}
      // onSlideChange={() => console.log("slide change")}
      // onSwiper={handleOnSetSwiper}
    >
      {imagesData.map((item, index) => {
        return (
          <SwiperSlide key={index} style={{ width: "600px" }}>
            <div key={index}>
              <img
                src={
                  `https://firebasestorage.googleapis.com/v0/b/wastecollectionproject-eecbb.appspot.com/o/` +
                  encodeURIComponent(item.slice(49)) +
                  "?alt=media&token=152fd981-c0ff-4ed0-93d1-bd3899e8e364"
                }
                alt="image"
                style={{
                  width: "400px",
                  height: "600px",
                  objectFit: "cover",
                  borderRadius: "10px",
                }}
              />
            </div>
          </SwiperSlide>
        );
      })}
      {/* <div className="container-dots" style={{ marginTop: "60px" }}>
    <div className="custom-pagination"></div>
  </div> */}
      <div className="swiper-button-prev"></div>
      <div className="swiper-button-next"></div>
    </Swiper>
  );
}

export default ImageSlider;
