import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import Sidenav from "../components/common/Sidenav";
import DashboardTopBar from "../components/common/DashboardTopBar";
import Overview from "../components/dashboard/Overview";
import UserFeedbacks from "../components/dashboard/UserFeedbacks";
import VendorData from "../components/dashboard/VendorData";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { db } from "../firebase";

function Dashboard() {
  const [active, setActive] = useState("overview");
  const [loading, setLoading] = useState(false);

  const [allLocation, setAllLocation] = useState([]);

  let locationSet = query(collection(db, "locations"), orderBy("id", "desc"));

  const getLocationData = async (location) => {
    getDocs(location)
      .then((res) => {
        return res.docs.map((item) => {
          return {
            ...item.data(),
          };
        });
      })
      .then((data) => {
        setAllLocation(data.reverse());
      });
    // const documentSnapshots = await getDocs(surveySet);
    // const lastVisiblea =
    //   documentSnapshots.docs[documentSnapshots.docs.length - 1];
    // lastVisiblea && setLastVisible(lastVisiblea);

    // const prevVisiblea = documentSnapshots.docs[0];
    // prevVisiblea && setLastVisible(prevVisiblea);
  };

  useEffect(() => {
    getLocationData(locationSet);
  }, []);

  useEffect(() => {
    const checkUserToken = () => {
      let token = localStorage.getItem("admin greentooth");
      if (!token || token === "undefined") {
        window.location = "/dashboard-login";
      } else {
        setLoading(true);
      }
    };
    checkUserToken();
  }, []);
  return (
    <>
      {loading ? (
        <Grid container sx={{ background: "#EEF0E4" }}>
          <Grid item lg={2} sx={{ position: "relative" }}>
            <Box
              sx={{
                height: "100vh",
                position: "fixed",
                width: "17%",
                // position: "relative",
                background: "#1E3226",
                p: 4,
              }}
            >
              <Box>
                <Sidenav active={active} setActive={setActive} />
              </Box>
            </Box>
          </Grid>
          <Grid item lg={10}>
            <DashboardTopBar />
            {active === "overview" && <Overview allLocation={allLocation} />}
            {active === "supervisor" && (
              <VendorData allLocation={allLocation} />
            )}
            {active === "feedbacks" && (
              <UserFeedbacks allLocation={allLocation} />
            )}
          </Grid>
        </Grid>
      ) : (
        <h1>Loading...</h1>
      )}
    </>
  );
}

export default Dashboard;
