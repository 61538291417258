import {
  Box,
  Button,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  collection,
  endBefore,
  getDocs,
  limit,
  limitToLast,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import moment from "moment";
import { CSVLink } from "react-csv";

function UserFeedbacks({ allLocation }) {
  const [userFeedbackData, setUserFeedbackData] = useState([]);
  const [location, setLocation] = useState("AllLocation");
  const [displayDate, setDisplayDate] = useState();
  const [showCalender, setShowCalender] = useState(false);
  const [limitOfData, setLimitOfData] = useState(300);
  const [lastVisible, setLastVisible] = useState();
  const [prevVisible, setPrevVisible] = useState();

  const [unixStart, setUnixStart] = useState();
  const [unixEnd, setUnixEnd] = useState();

  const headers = [
    { label: "Date", key: "createdAtStringDate" },
    { label: "Time", key: "createdAtStringTime" },
    { label: "Username", key: "userName" },
    { label: "Phone no.", key: "phone" },
    { label: "Location id", key: "location" },
    { label: "Location name", key: "locationName" },
    { label: "Message", key: "message" },
    { label: "Electricity Rating", key: "electricityRating" },
    { label: "Soap Rating", key: "soapRating" },
    { label: "Toilet Premises Rating", key: "toiletPremisesRating" },
    { label: "Toilet Seat Rating", key: "toiletSeatRating" },
    { label: "Water Rating", key: "waterRating" },
  ];
  const imgData = [
    "",
    "/assets/angry.png",
    "/assets/mad.png",
    "/assets/happy.png",
    "/assets/very-happy.png",
    "/assets/Excellent.png",
  ];

  const [state, setState] = useState({
    selection1: {
      startDate: addDays(new Date(), 0),
      endDate: addDays(new Date(), 0),
      key: "selection1",
      dateDivide: [],
    },
  });

  function handleCalendarChange(item) {
    setState({
      ...state,
      selection1: {
        startDate: item?.selection1?.startDate,
        endDate: item?.selection1?.endDate,
        key: "selection1",
      },
    });
  }

  const handleCalendarApply = () => {
    setShowCalender(false);
    let endofDay = moment(state?.selection1?.endDate).endOf("day").toString();
    let startofDay = moment(state?.selection1?.startDate)
      .startOf("day")
      .toString();
    let unixStartDate = moment(startofDay).unix();
    let unixEndDate = moment(endofDay).unix();

    let feedbackSetloc =
      location === "AllLocation"
        ? query(
            collection(db, "feedbacks"),
            where("createdAt", ">=", Number(unixStartDate)),
            where("createdAt", "<=", Number(unixEndDate)),
            orderBy("createdAt", "desc"),
            limit(limitOfData)
          )
        : query(
            collection(db, "feedbacks"),
            where("id", "==", location),
            where("createdAt", ">=", Number(unixStartDate)),
            where("createdAt", "<=", Number(unixEndDate)),
            orderBy("createdAt", "desc"),
            limit(limitOfData)
          );
    getUserFeedbackData(feedbackSetloc);
  };

  const handleLocation = (e) => {
    const loc = e.target.value;
    setShowCalender(false);
    let endofDay = moment(state?.selection1?.endDate).endOf("day").toString();
    let startofDay = moment(state?.selection1?.startDate)
      .startOf("day")
      .toString();

    let unixStartDate = moment(startofDay).unix();
    let unixEndDate = moment(endofDay).unix();
    setLocation(loc);
    let feedbackSetloc =
      e.target.value === "AllLocation"
        ? query(
            collection(db, "feedbacks"),
            where("createdAt", ">=", Number(unixStartDate)),
            where("createdAt", "<=", Number(unixEndDate)),
            orderBy("createdAt", "desc"),
            limit(limitOfData)
          )
        : query(
            collection(db, "feedbacks"),
            where("id", "==", loc),
            where("createdAt", ">=", Number(unixStartDate)),
            where("createdAt", "<=", Number(unixEndDate)),
            orderBy("createdAt", "desc"),
            limit(limitOfData)
          );
    getUserFeedbackData(feedbackSetloc);
  };
  const nextFeedbacks = async () => {
    const next =
      location === "AllLocation"
        ? query(
            collection(db, "feedbacks"),
            where("createdAt", ">=", Number(unixStart)),
            where("createdAt", "<=", Number(unixEnd)),
            orderBy("createdAt", "desc"),
            startAfter(lastVisible),
            limit(limitOfData)
          )
        : query(
            collection(db, "feedbacks"),
            where("id", "==", location),
            where("createdAt", ">=", Number(unixStart)),
            where("createdAt", "<=", Number(unixEnd)),
            orderBy("createdAt", "desc"),
            startAfter(lastVisible),
            limit(limitOfData)
          );

    getUserFeedbackData(next);
  };

  const prevFeedbacks = async () => {
    const next =
      location === "AllLocation"
        ? query(
            collection(db, "feedbacks"),
            where("createdAt", ">=", Number(unixStart)),
            where("createdAt", "<=", Number(unixEnd)),
            orderBy("createdAt", "desc"),
            endBefore(prevVisible),
            limitToLast(limitOfData)
          )
        : query(
            collection(db, "feedbacks"),
            where("id", "==", location),
            where("createdAt", ">=", Number(unixStart)),
            where("createdAt", "<=", Number(unixEnd)),
            orderBy("createdAt", "desc"),
            endBefore(prevVisible),
            limitToLast(limitOfData)
          );
    getUserFeedbackData(next);
  };

  const getUserFeedbackData = async (feedbackSet) => {
    const documentSnapshots = await getDocs(feedbackSet);
    const dataFeedback = documentSnapshots.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    const lastVisiblea =
      documentSnapshots.docs[documentSnapshots.docs.length - 1];
    lastVisiblea && setLastVisible(lastVisiblea);

    const prevVisiblea = documentSnapshots.docs[0];
    prevVisiblea && setPrevVisible(prevVisiblea);

    lastVisiblea &&
      prevVisiblea &&
      setUserFeedbackData(
        dataFeedback?.map((datawithLoc) => {
          const loc = allLocation.find(
            (item) => Number(item.id) === Number(datawithLoc?.location)
          );
          return {
            locationName: loc ? loc.location : null,
            ...datawithLoc,
          };
        })
      );

    if (dataFeedback.length === 0) {
      setUserFeedbackData([]);
    }
  };
  console.log(userFeedbackData, "userFeedbackData");
  useEffect(() => {
    let startofDay = moment(new Date()).startOf("day").toString();
    let unixStartDate = moment(startofDay).unix();
    let endofDay = moment(new Date()).endOf("day").toString();
    let unixEndDate = moment(endofDay).unix();
    let feedbackSet = query(
      collection(db, "feedbacks"),
      where("createdAt", ">=", Number(unixStartDate)),
      where("createdAt", "<=", Number(unixEndDate)),
      orderBy("createdAt", "desc"),
      limit(limitOfData)
    );

    getUserFeedbackData(feedbackSet);
  }, []);

  useEffect(() => {
    let startofDay = moment(state?.selection1?.startDate)
      .startOf("day")
      .toString();
    let endofDay = moment(state?.selection1?.endDate).endOf("day").toString();
    let unixStartDate = moment(startofDay).unix();
    let unixEndDate = moment(endofDay).unix();
    setUnixStart(unixStartDate);
    setUnixEnd(unixEndDate);
  }, [state]);

  useEffect(() => {
    const dateDivideStart = state?.selection1?.startDate?.toString().split(" ");
    const dateDivideEnd = state?.selection1?.endDate?.toString().split(" ");
    dateDivideStart &&
      dateDivideEnd &&
      setDisplayDate(
        `${dateDivideStart[2]} ${dateDivideStart[1]} - ${dateDivideEnd[2]}`
      );
  }, [state]);

  const csvReport = {
    filename: "userFeedbackData.csv",
    headers: headers,
    data: userFeedbackData?.map((data) => {
      const loc = allLocation.find(
        (item) => Number(item.id) === Number(data?.location)
      );

      return {
        locationName: loc ? loc.location : null,
        createdAtStringDate: moment
          .unix(data?.createdAt)
          .format("MMMM Do YYYY"),
        createdAtStringTime: moment.unix(data?.createdAt).format("h:mm a"),
        ...data,
      };
    }),
  };

  // console.log(userFeedbackData, "userFeedbackData", allLocation);

  return (
    <Box sx={{ p: 4 }}>
      <Box sx={{ background: "#fff", borderRadius: 2, p: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: 2,
            mt: 1,
            "& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input": {
              padding: "10px 38px 10px 10px",
            },
          }}
        >
          <Box>
            <Select
              value={location}
              onChange={handleLocation}
              className="test1"
            >
              <MenuItem value="AllLocation">All Location</MenuItem>

              {allLocation.map((item, index) => {
                return (
                  <MenuItem value={item.id} key={index} className="test2">
                    {item.location}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
          {/* <Calendar date={new Date()} onChange={handleSelect} /> */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              position: "relative",
            }}
          >
            <Button
              sx={{
                background: "#778A24",
                color: "#fff",
                border: 0,
                p: "7px 30px",
                width: "100%",
                "&:hover": {
                  background: "#778A2490",
                },
              }}
              onClick={() => setShowCalender((prevState) => !prevState)}
            >
              {displayDate}
            </Button>
            {showCalender && (
              <Box
                sx={{
                  position: "absolute",
                  left: "-420px",
                  top: "40px",
                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                  backgroundColor: "#fff",
                  zIndex: 10,
                }}
              >
                <DateRangePicker
                  onChange={(item) => {
                    handleCalendarChange(item);
                  }}
                  // moveRangeOnFirstSelection={false}
                  // retainEndDateOnFirstSelection={false}
                  maxDate={new Date()}
                  ranges={[state.selection1]}
                  rangeColors={["#1E3226"]}
                />
                <Box sx={{ backgroundColor: "#fff", position: "relative" }}>
                  <Button
                    sx={{
                      float: "right",
                      backgroundColor: "#006940",
                      color: "#fff",
                      p: "5px 24px",
                      mx: 2,
                      mb: 1,
                    }}
                    onClick={handleCalendarApply}
                  >
                    Apply
                  </Button>
                  <Button
                    sx={{
                      float: "right",
                      border: "1px solid #006940",
                      color: "#006940",
                      p: "5px 24px",
                      mx: 0,
                      mb: 1,
                    }}
                    onClick={() => {
                      setShowCalender(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
          <Button
            sx={{
              background: "#006940",
              color: "#fff",
              border: 0,
              p: "7px 30px",
              "&:hover": {
                background: "#00694080",
              },
            }}
          >
            <CSVLink {...csvReport} style={{ color: "#fff" }}>
              Export Data
            </CSVLink>
          </Button>
          {/* <LocalizationProvider
            dateAdapter={AdapterDayjs}
            className="date-picker"
          >
            <DateRangePicker
              sx={{
                p: "7px",
                "& .MuiInputBase-input.MuiOutlinedInput-input": {
                  padding: "7px",
                },
              }}
              slots={{ field: SingleInputDateRangeField }}
            />
          </LocalizationProvider> */}
        </Box>
        <TableContainer>
          <Table sx={{ minWidth: 650, mt: 4 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Location</TableCell>

                <TableCell align="left">Username</TableCell>
                <TableCell align="left">Phone no.</TableCell>
                <TableCell align="left">Message</TableCell>
                <TableCell align="center">Electricity</TableCell>
                <TableCell align="center">Soap</TableCell>
                <TableCell align="center">Toilet Premises</TableCell>
                <TableCell align="center">Toilet Seat</TableCell>
                <TableCell align="center">Water</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userFeedbackData.map((item, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell align="left">{item.locationName}</TableCell>

                    <TableCell align="left">{item.userName}</TableCell>
                    <TableCell align="left">{item.phone}</TableCell>
                    <TableCell align="left">{item.message}</TableCell>
                    <TableCell sx={{ textAlign: "center" }} align="center">
                      <img
                        src={imgData[item.electricityRating]}
                        style={{ width: "30px", height: "30px" }}
                      />
                      <br></br>
                      {item.electricityRating === 1
                        ? "Worst"
                        : item.electricityRating === 2
                        ? "Poor"
                        : item.electricityRating === 3
                        ? "Average"
                        : item.electricityRating === 4
                        ? "Good"
                        : "Excellent"}
                    </TableCell>
                    <TableCell align="center">
                      <img
                        src={imgData[item.soapRating]}
                        style={{ width: "30px", height: "30px" }}
                      />
                      <br></br>
                      {/* {item.soapRating} */}
                      {item.soapRating === 1
                        ? "Worst"
                        : item.soapRating === 2
                        ? "Poor"
                        : item.soapRating === 3
                        ? "Average"
                        : item.soapRating === 4
                        ? "Good"
                        : "Excellent"}
                    </TableCell>
                    <TableCell align="center">
                      <img
                        src={imgData[item.toiletPremisesRating]}
                        style={{ width: "30px", height: "30px" }}
                      />
                      <br></br>
                      {/* {item.toiletPremisesRating} */}
                      {item.toiletPremisesRating === 1
                        ? "Worst"
                        : item.toiletPremisesRating === 2
                        ? "Poor"
                        : item.toiletPremisesRating === 3
                        ? "Average"
                        : item.toiletPremisesRating === 4
                        ? "Good"
                        : "Excellent"}
                    </TableCell>
                    <TableCell align="center">
                      <img
                        src={imgData[item.toiletSeatRating]}
                        style={{ width: "30px", height: "30px" }}
                      />
                      <br></br>
                      {/* {item.toiletSeatRating} */}
                      {item.toiletSeatRating === 1
                        ? "Worst"
                        : item.toiletSeatRating === 2
                        ? "Poor"
                        : item.toiletSeatRating === 3
                        ? "Average"
                        : item.toiletSeatRating === 4
                        ? "Good"
                        : "Excellent"}
                    </TableCell>
                    <TableCell align="center">
                      <img
                        src={imgData[item.waterRating]}
                        style={{ width: "30px", height: "30px" }}
                      />
                      <br></br>
                      {/* {item.waterRating} */}
                      {item.waterRating === 1
                        ? "Worst"
                        : item.waterRating === 2
                        ? "Poor"
                        : item.waterRating === 3
                        ? "Average"
                        : item.waterRating === 4
                        ? "Good"
                        : "Excellent"}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {userFeedbackData.length < 1 && (
          <Typography sx={{ textAlign: "center", fontWeight: 500, my: 4 }}>
            NO DATA FOUND
          </Typography>
        )}
        {!userFeedbackData.length < 1 && (
          <Box
            sx={{ display: "flex", justifyContent: "center", gap: 2, my: 4 }}
          >
            <Button
              onClick={prevFeedbacks}
              sx={{
                width: "100px",
                background: "#A2B05850",
                color: "#1E3226",
              }}
            >
              Prev
            </Button>
            <Button
              onClick={nextFeedbacks}
              sx={{ width: "100px", background: "#A2B058", color: "#1E3226" }}
            >
              Next
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default UserFeedbacks;
