import React from "react";
import HeroSection from "../common/HeroSection";
import Footer from "../common/Footer";
import Header from "../common/Header";
import ServicesSection from "./ServicesSection";
import "../../App.css";
import About from "./About";
import ContactUs from "./ContactUs";
import BottomBar from "../common/BottomBar";
import Awards from "./Awards";

function Home() {
  return (
    <main
      style={{
        backgroundColor: "#fff",
      }}
    >
      <Header />
      <HeroSection />
      <ServicesSection />
      <Awards />
      <About />
      <ContactUs />
      <Footer />
      <BottomBar />
      <div
        className="whatsAppIcon"
        style={{
          position: "fixed",
          left: "10px",
          zIndex: "999999999",
          top: "auto",
          bottom: "7px",
          width: "60px",
        }}
      >
        <a
          target="_blank"
          href="https://wa.me/919289097931"
          aria-label="Let's Connect"
        >
          <img
            src="/assets/whatsapp.webp"
            alt="whatsapp"
            width={60}
            height={60}
          />
        </a>
      </div>
    </main>
  );
}

export default Home;
