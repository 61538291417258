import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCSoqjn0gqI9NCfmm-OVP-bxvckQ9mnmic",
  authDomain: "wastecollectionproject-eecbb.firebaseapp.com",
  projectId: "wastecollectionproject-eecbb",
  storageBucket: "wastecollectionproject-eecbb.appspot.com",
  messagingSenderId: "650301638154",
  appId: "1:650301638154:web:152562c661d797ee1f01f3",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
