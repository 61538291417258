import { Box, Grid, List, ListItem, Typography } from "@mui/material";
import React from "react";
import ServiceCard from "../common/ServiceCard";

function ServicesSection() {
  const serviceLists = [
    {
      title: "Decentralised Waste Management",
      info: "Decentralized waste management is an innovative approach to handling and disposing of waste that emphasizes local participation and decision-making. In this system, waste is managed at or near the source of generation, reducing the need for long-distance transportation and minimizing the associated environmental impacts.",
      imgVid: "/assets/municipalSolidWaste.webp",
      imgLeft: false,
      url: "#website-designing",
    },
    {
      title: "Waste Processing through Composting pits",
      info: "Waste processing through composting pits is an effective method of organic waste management. Composting pits are designed structures or designated areas where organic waste, such as food scraps, yard trimmings, and other biodegradable materials, can be decomposed naturally.",
      imgVid: "/assets/msw.webp",
      imgLeft: true,
      url: "#video-invitation",
    },
    {
      title: "MSW Segregation",
      info: "Municipal Solid Waste (MSW) segregation is the process of separating different types of waste materials in order to facilitate proper disposal and recycling. It involves categorizing waste into distinct groups based on their characteristics, such as recyclables, organic waste, hazardous materials, and non-recyclable/non-biodegradable waste.",
      imgVid: "/assets/mswOps.webp",
      imgLeft: false,
      url: "#budget-planning",
    },
    {
      title: "Material Recovery Facility (MRF)",
      info: "A Material Recovery Facility (MRF) is a specialized facility designed for the sorting and processing of recyclable materials. It plays a crucial role in the recycling industry by efficiently separating different types of waste materials, such as paper, plastic, metal, and glass, for further processing and reuse.",
      imgVid: "/assets/TransferMSWOps.webp",
      imgLeft: true,
      url: "#guest-management",
    },
    {
      title: "MSW Secondary Operation",
      info: "MSW (Municipal Solid Waste) secondary operation refers to the additional treatment or processing steps that are carried out on solid waste after its initial collection and primary sorting. These secondary operations aim to further enhance waste management practices, maximize resource recovery, and minimize the environmental impact of waste disposal.",
      imgVid: "/assets/MSWsecondaryOps.webp",
      imgLeft: false,
      url: "#guest-management",
    },
    {
      title: "MSW Processing",
      info: "MSW (Municipal Solid Waste) processing refers to the overall management and treatment of solid waste generated by households, commercial establishments, and institutions within a municipality.",
      imgVid: "/assets/wasteProcessing.webp",
      imgLeft: true,
      url: "#guest-management",
    },
  ];
  return (
    <section className="layoutMargin" id="services">
      <Box
        sx={{
          px: { lg: "0px", xs: "40px" },
        }}
      >
        <Typography
          variant="h2"
          className="caveatFont"
          sx={{ color: "#778A24", mt: 2, textAlign: "center" }}
        >
          Our Services
        </Typography>
        <Typography variant="body1" sx={{ textAlign: "center" }}>
          Customize your invitation and choose 'send online' to create your
          event..
        </Typography>

        {serviceLists.map((list, index) => {
          return (
            <ServiceCard
              key={index}
              title={list.title}
              info={list.info}
              img={list.img}
              imgLeft={list.imgLeft}
              url={list.url}
              imgVid={list.imgVid}
            />
          );
        })}
      </Box>
    </section>
  );
}

export default ServicesSection;
