import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./FeedbackSection.css";
import { Box } from "@mui/material";

function FeedbackCard({ ques, setFormData, quesNum }) {
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);

  const navigate = useNavigate();
  const handleClickFeedback = (refVal, noRef, rating) => {
    refVal.current.style.opacity = 1;
    noRef.map((removeOpacity) => {
      removeOpacity.current.style.opacity = 0.2;
    });

    let name;
    if (quesNum === "1") {
      setFormData((prevData) => {
        return {
          ...prevData,
          electricityRating: rating,
        };
      });
    } else if (quesNum === "2") {
      setFormData((prevData) => {
        return {
          ...prevData,
          waterRating: rating,
        };
      });
    } else if (quesNum === "3") {
      setFormData((prevData) => {
        return {
          ...prevData,
          soapRating: rating,
        };
      });
    } else if (quesNum === "4") {
      setFormData((prevData) => {
        return {
          ...prevData,
          toiletPremisesRating: rating,
        };
      });
    } else if (quesNum === "5") {
      setFormData((prevData) => {
        return {
          ...prevData,
          toiletSeatRating: rating,
        };
      });
    }

    // for (let i = 0; i <= img.length; i++) {
    //   if (i === num) {
    //     img[i - 1].style.background = "red";
    //   } else {
    //     img[i].style.background = "transparent";
    //   }
    // }
  };

  return (
    <Box>
      <h4>{ques}</h4>
      <Box className="d-flex flex-row mt-2 ">
        <img
          src="/assets/angry.png"
          ref={ref1}
          onClick={() => handleClickFeedback(ref1, [ref2, ref3, ref4, ref5], 1)}
          className="emojiicon"
        />
        <img
          src="/assets/mad.png"
          onClick={() => handleClickFeedback(ref2, [ref1, ref3, ref4, ref5], 2)}
          className="emojiicon"
          ref={ref2}
        />
        <img
          src="/assets/happy.png"
          onClick={() => handleClickFeedback(ref3, [ref2, ref1, ref4, ref5], 3)}
          className="emojiicon"
          ref={ref3}
        />
        <img
          src="/assets/very-happy.png"
          onClick={() => handleClickFeedback(ref4, [ref2, ref3, ref1, ref5], 4)}
          className="emojiicon"
          ref={ref4}
        />
        <img
          src="/assets/Excellent.png"
          onClick={() => handleClickFeedback(ref5, [ref2, ref3, ref4, ref1], 5)}
          className="emojiicon"
          ref={ref5}
        />
      </Box>
      <hr></hr>
    </Box>
  );
}

export default FeedbackCard;
