import "./App.css";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import Home from "./components/home/Home";
import FeedbackPage from "./pages/FeedbackPage";
import ThankYou from "./pages/ThankYou";
import Dashboard from "./pages/Dashboard";
import DashboardLogin from "./pages/DashboardLogin";
import { useEffect, useState } from "react";
import PageError from "./pages/PageError";

function App() {
  const [token, setToken] = useState(null);

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/dashboard-login" element={<DashboardLogin />} />

          <Route exact path="/thank-you" element={<ThankYou />} />

          <Route exact path="/feedbacks/:id" element={<FeedbackPage />} />
          <Route exact path="*" element={<PageError />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
