import {
  Avatar,
  Box,
  Card,
  CardContent,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

function OverviewCard({
  title,
  img,
  currentData,
  currentDataLength,
  // Add 'key' prop if used in a list: key={someUniqueId}
}) {
  return (
    <Card sx={{ borderRadius: "7px" }}>
      <CardContent>
        <Stack
          alignItems="flex-start"
          direction="row"
          justifyContent="space-between"
          spacing={3}
        >
          <Stack spacing={1}>
            <Typography color="text.secondary" variant="overline">
              Supervisor Data
            </Typography>
            <Typography variant="h6">{title}</Typography>
            <Typography color="text.secondary" variant="caption">
              Since today
            </Typography>
          </Stack>
          <img
            src={img}
            style={{ width: "60px", height: "auto" }}
            alt={title}
          />
        </Stack>

        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <CheckCircleIcon sx={{ color: "green" }} size={20} />
              <Box>
                <Typography variant="h6">On</Typography>
              </Box>
            </Box>
            <Typography
              variant="body1"
              sx={{ textAlign: "center", fontWeight: 500 }}
            >
              {isNaN((currentData[0] / currentDataLength) * 100)
                ? "0"
                : ((currentData[0] / currentDataLength) * 100).toFixed(2)}
              %
            </Typography>
          </Box>
          <Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <CancelIcon sx={{ color: "red" }} size={20} />
              <Box>
                <Typography variant="h6">Off</Typography>
              </Box>
            </Box>
            <Typography
              variant="body1"
              sx={{ textAlign: "center", fontWeight: 500 }}
            >
              {isNaN((currentData[1] / currentDataLength) * 100)
                ? "0"
                : ((currentData[1] / currentDataLength) * 100).toFixed(2)}
              %
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

export default OverviewCard;
