import React from "react";
// import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
// import { Pie } from "react-chartjs-2";
import { Box } from "@mui/material";
import Chart from "react-google-charts";

// ChartJS.register(ArcElement, Tooltip, Legend);

function PieCard({ pieData }) {
  // const data = {
  //   //   labels: ["Yes", "No"],
  //   datasets: [
  //     {
  //       data: pieData,
  //       backgroundColor: ["#F5B849", "#006940"],
  //       borderColor: ["#F5B849", "#006940"],
  //       borderWidth: 1,
  //     },
  //   ],
  // };
  const data = pieData && [
    ["Task", "Toilet Cleaning"],
    ["Yes", pieData[0]],
    ["No", pieData[1]],
  ];
  var options = {
    legend: "none",
    colors: ["#F5B849", "#006940"],
  };
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
      }}
    >
      <Box>
        {/* <Pie data={data} /> */}
        {pieData && (
          <Chart
            chartType="PieChart"
            data={data}
            options={options}
            width={"350px"}
            height={"350px"}
          />
        )}
      </Box>
    </Box>
  );
}

export default PieCard;
