import {
  Box,
  Button,
  Dialog,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Pagination,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  collection,
  endBefore,
  getDocs,
  limit,
  limitToLast,
  orderBy,
  query,
  startAfter,
  startAt,
  where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import { DateRangePicker } from "react-date-range";
import { addDays, set } from "date-fns";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import moment from "moment/moment";
import { CSVLink } from "react-csv";
import ImageSlider from "../common/ImageSlider";

function VendorData({ allLocation }) {
  const [surveyData, setSurveyData] = useState([]);
  const [location, setLocation] = useState("AllLocation");
  const [displayDate, setDisplayDate] = useState();
  const [showCalender, setShowCalender] = useState(false);

  const [limitOfData, setLimitOfData] = useState(300);
  const [lastVisible, setLastVisible] = useState();
  const [prevVisible, setPrevVisible] = useState();

  const [open, setOpen] = useState(false);

  const [imagesData, setImagesData] = useState([]);

  const [unixStart, setUnixStart] = useState();
  const [unixEnd, setUnixEnd] = useState();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [step, setStep] = useState(1);

  const headers = [
    { label: "id", key: "id" },
    { label: "Location", key: "location" },
    { label: "Date", key: "createdAtStringDate" },
    { label: "Time", key: "createdAtStringTime" },
    { label: "Clean Premises", key: "cleanPremises" },
    { label: "Clean Seat", key: "cleanSeat" },
    { label: "Electricity", key: "electricity" },
    { label: "Soap", key: "soap" },
    { label: "Water", key: "water" },
    { label: "Username", key: "userName" },
  ];

  const [state, setState] = useState({
    selection1: {
      startDate: addDays(new Date(), 0),
      endDate: addDays(new Date(), 0),
      key: "selection1",
      dateDivide: [],
    },
  });

  function handleCalendarChange(item) {
    setState({
      ...state,
      selection1: {
        startDate: item?.selection1?.startDate,
        endDate: item?.selection1?.endDate,
        key: "selection1",
      },
    });
  }

  const handleCalendarApply = () => {
    setShowCalender(false);
    let endofDay = moment(state?.selection1?.endDate).endOf("day").toString();
    let unixStartDate = moment(state?.selection1?.startDate).unix() + "000";
    let unixEndDate = moment(endofDay).unix() + "000";

    let surevySetDate =
      location === "AllLocation"
        ? query(
            collection(db, "survey"),
            where("createdOn", ">=", Number(unixStartDate)),
            where("createdOn", "<=", Number(unixEndDate)),
            orderBy("createdOn", "desc"),
            limit(limitOfData)
          )
        : query(
            collection(db, "survey"),
            where("id", "==", String(location)),
            where("createdOn", ">=", Number(unixStartDate)),
            where("createdOn", "<=", Number(unixEndDate)),
            orderBy("createdOn", "desc"),
            limit(limitOfData)
          );
    getSurveyData(surevySetDate);
  };

  const handleLocation = (e) => {
    const loc = e.target.value;
    setShowCalender(false);
    let endofDay = moment(state?.selection1?.endDate).endOf("day").toString();
    let startofDay = moment(state?.selection1?.startDate)
      .startOf("day")
      .toString();

    let unixStartDate = moment(startofDay).unix() + "000";
    let unixEndDate = moment(endofDay).unix() + "000";
    setLocation(loc);

    let surevySetloc =
      e.target.value === "AllLocation"
        ? query(
            collection(db, "survey"),
            where("createdOn", ">=", Number(unixStartDate)),
            where("createdOn", "<=", Number(unixEndDate)),
            limit(limitOfData)
          )
        : query(
            collection(db, "survey"),
            where("id", "==", String(e.target.value)),
            where("createdOn", ">=", Number(unixStartDate)),
            where("createdOn", "<=", Number(unixEndDate)),
            limit(limitOfData)
          );
    // let feedbackSetloc = query(
    //   collection(db, "survey"),
    //   where("id", "==", e.target.value),
    //   //limit(limitOfData)
    // );
    setLocation(e.target.value);
    getSurveyData(surevySetloc);
  };

  let surveySet = query(
    collection(db, "survey"),
    // where("location", "==", location),
    orderBy("createdOn", "desc"),
    limit(limitOfData)
  );

  const nextSurvey = async () => {
    const next =
      location === "AllLocation"
        ? query(
            collection(db, "survey"),
            where("createdOn", ">=", Number(unixStart)),
            where("createdOn", "<=", Number(unixEnd)),
            orderBy("createdOn", "desc"),
            startAfter(lastVisible),
            limit(limitOfData)
          )
        : query(
            collection(db, "survey"),
            where("location", "==", location),
            where("createdOn", ">=", Number(unixStart)),
            where("createdOn", "<=", Number(unixEnd)),
            orderBy("createdOn", "desc"),
            startAfter(lastVisible),
            limit(limitOfData)
          );
    getSurveyData(next);
  };

  const prevSurvey = async () => {
    const next =
      location === "AllLocation"
        ? query(
            collection(db, "survey"),
            where("createdOn", ">=", Number(unixStart)),
            where("createdOn", "<=", Number(unixEnd)),
            orderBy("createdOn", "desc"),
            endBefore(prevVisible),
            limitToLast(limitOfData)
          )
        : query(
            collection(db, "survey"),
            where("location", "==", location),
            where("createdOn", ">=", Number(unixStart)),
            where("createdOn", "<=", Number(unixEnd)),
            orderBy("createdOn", "desc"),
            endBefore(prevVisible),
            limitToLast(limitOfData)
          );

    getSurveyData(next);
  };

  const getSurveyData = async (surveySet) => {
    const documentSnapshots = await getDocs(surveySet);
    const dataVendor = documentSnapshots.docs.map((doc) => ({
      ...doc.data(),
    }));

    const lastVisiblea =
      documentSnapshots.docs[documentSnapshots.docs.length - 1];
    lastVisiblea && setLastVisible(lastVisiblea);

    const prevVisiblea = documentSnapshots.docs[0];
    prevVisiblea && setPrevVisible(prevVisiblea);

    lastVisiblea &&
      prevVisiblea &&
      setSurveyData(
        dataVendor.map((datawithLoc) => {
          const loc = allLocation.find(
            (item) => Number(item.id) === Number(datawithLoc?.id)
          );
          return {
            locationName: loc ? loc.location : null,
            ...datawithLoc,
          };
        })
      );

    if (dataVendor.length === 0) {
      setSurveyData([]);
    }
  };

  useEffect(() => {
    let startofDay = moment(new Date()).startOf("day").toString();
    let unixStartDate = moment(startofDay).unix() + "000";
    let endofDay = moment(new Date()).endOf("day").toString();
    let unixEndDate = moment(endofDay).unix() + "000";

    let surevyFirstTime = query(
      collection(db, "survey"),
      where("createdOn", ">=", Number(unixStartDate)),
      where("createdOn", "<=", Number(unixEndDate)),
      orderBy("createdOn", "desc"),
      limit(limitOfData)
    );

    getSurveyData(surevyFirstTime);
  }, []);

  useEffect(() => {
    let startofDay = moment(state?.selection1?.startDate)
      .startOf("day")
      .toString();
    let endofDay = moment(state?.selection1?.endDate).endOf("day").toString();
    let unixStartDate = moment(startofDay).unix() + "000";
    let unixEndDate = moment(endofDay).unix() + "000";
    setUnixStart(unixStartDate);
    setUnixEnd(unixEndDate);
  }, [state]);

  useEffect(() => {
    const dateDivideStart = state?.selection1?.startDate?.toString().split(" ");
    const dateDivideEnd = state?.selection1?.endDate?.toString().split(" ");
    dateDivideStart &&
      dateDivideEnd &&
      setDisplayDate(
        `${dateDivideStart[2]} ${dateDivideStart[1]} - ${dateDivideEnd[2]}`
      );
  }, [state]);

  const csvReport = {
    filename: "supervisorData.csv",
    headers: headers,
    data: surveyData.map((data) => {
      const loc = allLocation.find(
        (item) => Number(item.id) === Number(data.id)
      );
      return {
        location: loc?.location,
        createdAtStringDate: moment(data?.createdOn).format("MMMM Do YYYY"),
        createdAtStringTime: moment(data?.createdOn).format(" h:mm A "),
        ...data,
      };
    }),
  };

  return (
    <Box sx={{ p: 4 }}>
      <Box sx={{ background: "#fff", borderRadius: 2, p: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            position: "relative",
            gap: 2,
            mt: 1,
            "& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input": {
              padding: "10px 38px 10px 10px",
            },
          }}
        >
          <Select value={location} onChange={handleLocation} className="test1">
            <MenuItem value="AllLocation">All Location</MenuItem>

            {allLocation.map((item, index) => {
              return (
                <MenuItem value={item.id} key={item.id} className="test2">
                  {item.location}
                </MenuItem>
              );
            })}
          </Select>
          {/* <Calendar date={new Date()} onChange={handleSelect} /> */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              position: "relative",
            }}
          >
            <Button
              sx={{
                background: "#778A24",
                color: "#fff",
                border: 0,
                p: "7px 30px",
                width: "100%",
                "&:hover": {
                  background: "#778A2490",
                },
              }}
              onClick={() => setShowCalender((prevState) => !prevState)}
            >
              {displayDate}
            </Button>
            {showCalender && (
              <Box
                sx={{
                  position: "absolute",
                  left: "-420px",
                  top: "40px",
                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                  backgroundColor: "#fff",
                  zIndex: 10,
                }}
              >
                <DateRangePicker
                  onChange={(item) => {
                    handleCalendarChange(item);
                  }}
                  // moveRangeOnFirstSelection={false}
                  // retainEndDateOnFirstSelection={false}
                  maxDate={new Date()}
                  ranges={[state.selection1]}
                  rangeColors={["#1E3226"]}
                />
                <Box sx={{ backgroundColor: "#fff", position: "relative" }}>
                  <Button
                    sx={{
                      float: "right",
                      backgroundColor: "#006940",
                      color: "#fff",
                      p: "5px 24px",
                      mx: 2,
                      mb: 1,
                    }}
                    onClick={handleCalendarApply}
                  >
                    Apply
                  </Button>
                  <Button
                    sx={{
                      float: "right",
                      border: "1px solid #006940",
                      color: "#006940",
                      p: "5px 24px",
                      mx: 0,
                      mb: 1,
                    }}
                    onClick={() => {
                      setShowCalender(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
          <Button
            sx={{
              background: "#006940",
              color: "#fff",
              border: 0,
              p: "7px 30px",
              "&:hover": {
                background: "#00694080",
              },
            }}
          >
            <CSVLink {...csvReport} style={{ color: "#fff" }}>
              Export Data
            </CSVLink>
          </Button>
        </Box>
        <TableContainer>
          <Table sx={{ minWidth: 650, mt: 4 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Location</TableCell>
                <TableCell align="center">Username</TableCell>
                <TableCell align="center">Electricity</TableCell>
                <TableCell align="center">Soap</TableCell>
                <TableCell align="center">Toilet Premises</TableCell>
                <TableCell align="center">Toilet Seat</TableCell>
                <TableCell align="center">Water</TableCell>
                <TableCell align="center">Images</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {surveyData.map((item, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell align="center">{item.locationName}</TableCell>
                    <TableCell align="center">{item.userName}</TableCell>

                    <TableCell sx={{ textAlign: "center" }} align="center">
                      {item.electricity ? (
                        <CheckCircleIcon sx={{ color: "green" }} />
                      ) : (
                        <CancelIcon sx={{ color: "red" }} />
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {item.soap ? (
                        <CheckCircleIcon sx={{ color: "green" }} />
                      ) : (
                        <CancelIcon sx={{ color: "red" }} />
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {item.cleanPremises ? (
                        <CheckCircleIcon sx={{ color: "green" }} />
                      ) : (
                        <CancelIcon sx={{ color: "red" }} />
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {item.cleanSeat ? (
                        <CheckCircleIcon sx={{ color: "green" }} />
                      ) : (
                        <CancelIcon sx={{ color: "red" }} />
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {item.electricity ? (
                        <CheckCircleIcon sx={{ color: "green" }} />
                      ) : (
                        <CancelIcon sx={{ color: "red" }} />
                      )}
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        sx={{
                          background: "#006940",
                          color: "#fff",
                          border: 0,
                          p: "7px 30px",
                          "&:hover": {
                            background: "#00694080",
                          },
                        }}
                        onClick={() => {
                          handleOpen();
                          setImagesData(JSON.parse(item.photos));
                        }}
                      >
                        View Image
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {surveyData.length < 1 && (
          <Typography sx={{ textAlign: "center", fontWeight: 500, my: 4 }}>
            NO DATA FOUND
          </Typography>
        )}
        {!surveyData.length < 1 && (
          <Box
            sx={{ display: "flex", justifyContent: "center", gap: 2, my: 4 }}
          >
            <Button
              onClick={prevSurvey}
              sx={{ width: "100px", background: "#A2B05850", color: "#1E3226" }}
            >
              Prev
            </Button>
            <Button
              onClick={nextSurvey}
              sx={{ width: "100px", background: "#A2B058", color: "#1E3226" }}
            >
              Next
            </Button>
          </Box>
        )}
      </Box>
      <Dialog open={open} onClose={handleClose} maxWidth="md">
        <Box sx={{ p: 4, background: "#fff", position: "relative" }}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ position: "absolute", right: 20, top: 10 }}
          >
            <CancelIcon sx={{ fontSize: "30px" }} />
          </IconButton>
          <Typography variant="h5" sx={{ py: 2 }}>
            Images clicked by the supervisor
          </Typography>
          <Box sx={{ display: "flex", gap: 4 }}>
            <ImageSlider imagesData={imagesData} />
            {/* {imagesData.map((item, index) => {
              return (
                <div key={index}>
                  <img
                    src={
                      `https://firebasestorage.googleapis.com/v0/b/wastecollectionproject-eecbb.appspot.com/o/` +
                      encodeURIComponent(item.slice(49)) +
                      "?alt=media&token=152fd981-c0ff-4ed0-93d1-bd3899e8e364"
                    }
                    alt="image"
                    style={{
                      width: "250px",
                      height: "250px",
                      objectFit: "cover",
                      flexGrow: 1,
                      borderRadius: "10px",
                    }}
                  />
                </div>
              );
            })} */}
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
}

export default VendorData;
