import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import { FaFacebookF, FaPhone } from "react-icons/fa";
import { BsTwitter } from "react-icons/bs";
import { FiInstagram } from "react-icons/fi";
import { HiMail } from "react-icons/hi";
import { ImLocation } from "react-icons/im";
import emailjs from "@emailjs/browser";
import { Toaster, toast } from "react-hot-toast";

function ContactUs() {
  const contactInfo = [
    {
      title: "Call Anytime",
      value: "+91 92890 97931",
      icon: <FaPhone style={{ marginRight: 4 }} size={24} />,
    },
    {
      title: "Write Email",
      value: "am@greentooth.in",
      icon: <HiMail style={{ marginRight: 4 }} size={24} />,
    },

    {
      title: "Visit Us Anytime",
      value:
        "MCG Material Recovery Facility, Near Shiksha Bharti Public School, Kadarpur Road, Badshahpur, Gurugram 122101",
      icon: <ImLocation style={{ marginRight: 4 }} size={24} />,
    },
  ];

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_dyjmtdp",
        "template_wwxx0ne",
        form.current,
        "FQuLYaxszAPLmQLtW"
      )
      .then(
        (result) => {
          toast.success("Your contact form submitted");
          setName("");
          setEmail("");
          setMessage("");
        },
        (error) => {
          toast.error("Submission failed");
        }
      );
  };
  return (
    <section id="contact">
      <Toaster />

      <Box>
        <Typography
          variant="h2"
          className="caveatFont"
          sx={{ color: "#778A24", mt: 10, textAlign: "center" }}
        >
          Contact Us
        </Typography>

        <Typography variant="body1" sx={{ textAlign: "center" }}>
          Have Questions? Feel Free to Write Us
        </Typography>
        <Grid
          container
          sx={{ my: { lg: 10, xs: "40px", textAlign: "start" } }}
          className="layoutMargin"
        >
          <Grid
            item
            lg={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              px: { lg: 0, xs: "20px" },
            }}
          >
            <Typography variant="h6" sx={{ fontSize: "30px" }}>
              Have Questions? Feel Free to Write Us
            </Typography>
            <Typography
              variant="body1"
              sx={{ mr: 10, my: 2, textAlign: "justify" }}
            >
              If you have any questions or inquiries, we are here to help! We
              value your feedback and are eager to assist you.
            </Typography>

            {contactInfo.map((contact, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    mt: 2,
                    alignItems: "start",
                  }}
                >
                  <Box
                    sx={{
                      background: "#778A24",
                      p: 3,
                      mr: 4,
                      borderRadius: "7px",
                      display: "flex",
                      alignItems: "center",
                      color: "#fff",
                    }}
                  >
                    {contact.icon}
                  </Box>
                  <Box>
                    <Typography
                      variant="h5"
                      sx={{ fontSize: "16px", textAlign: "start" }}
                    >
                      {contact.title}
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: "20px",
                        textAlign: "start",
                        textAlign: "justify",
                        pr: 4,
                      }}
                    >
                      {contact.value}
                    </Typography>
                  </Box>
                </Box>
              );
            })}
          </Grid>
          <Grid item lg={6}>
            <Box
              sx={{
                border: "1px solid #778A24",
                p: 4,
                borderRadius: "7px",
                mt: { lg: 0, xs: "40px" },
                mx: { lg: 0, xs: "20px" },
              }}
            >
              <form ref={form} onSubmit={sendEmail}>
                <TextField
                  placeholder="Your name"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  sx={{
                    background: "#778A2420",
                    border: 0,
                    width: "100%",
                    "& .Mui-focused.MuiOutlinedInput-notchedOutline": {
                      border: "1px solid green",
                    },
                  }}
                />
                <TextField
                  placeholder="Email address"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  sx={{
                    background: "#778A2420",
                    width: "100%",
                    mt: 4,
                    "&::focus": {
                      border: "1px solid green",
                    },
                  }}
                />
                <TextField
                  placeholder="Write message"
                  name="message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  multiline
                  rows={5}
                  sx={{
                    background: "#778A2420",
                    border: 0,
                    width: "100%",
                    mt: 4,
                    "& .Mui-focused": {
                      border: "0px",
                    },
                  }}
                />
                <Button
                  type="submit"
                  disabled={!name && !email && !message}
                  sx={{
                    background: "#778A24",
                    color: "#fff",
                    border: 0,
                    p: "7px 30px",
                    width: "100%",
                    mt: 4,
                    "&:hover": {
                      background: "#778A2490",
                    },
                  }}
                >
                  Send Message
                </Button>
              </form>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </section>
  );
}

export default ContactUs;
