import {
  AppBar,
  Box,
  Button,
  Divider,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";

function Header({ colorCh }) {
  const navItems = [
    { title: "Home", url: "/" },
    { title: "Our Services", url: "#services" },
    { title: "Awards", url: "#awards" },
    { title: "About", url: "#about" },
    { title: "Contact Us", url: "#contact" },
  ];

  const [colorChange, setColorchange] = useState(false);
  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{
        textAlign: "start",
        background: "#fff",
        width: "100%",
        color: "#000",
        boxShadow: " 0 2px 4px 0 rgba(0,0,0,0.2)",
      }}
    >
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem
            key={item}
            sx={{
              textDecoration: "none",
            }}
          >
            <Link
              href={item.url}
              key={item}
              sx={{
                color: "#000",
                padding: "0px 20px",
                textDecoration: "none",
                fontWeight: 500,
                "&:hover": {
                  color: "#00000020",
                },
              }}
            >
              <ListItemText
                sx={{
                  "&:hover": {
                    color: "#00000020",
                  },
                }}
                primary={item.title}
              />
            </Link>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  useEffect(() => {
    window.addEventListener("scroll", changeNavbarColor);
  }, [colorChange]);
  return (
    <header style={{ position: "absolute", left: 0 }}>
      <AppBar
        component="nav"
        sx={{
          backgroundColor: "#fff",
          boxShadow: !colorChange && colorCh ? "block" : "none",
          color: "#000",
        }}
      >
        <Toolbar
          sx={{
            color: "#000",
            display: "flex",
            justifyContent: "space-between",
            mx: { lg: 20 },
            "&:hover": {
              color: "#778A24",
            },
          }}
        >
          <img src="/assets/hero-header.webp" className="logoHome" />

          <IconButton
            color="#000"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { lg: "none" }, background: "#fff" }}
          >
            <MenuIcon />
          </IconButton>

          <Box sx={{ display: { xs: "none", lg: "block" } }}>
            {navItems.map((item, index) => (
              <Link
                href={item.url}
                key={index}
                sx={{
                  color: "#000",
                  padding: "0px 20px",
                  textDecoration: "none",
                  fontWeight: 500,
                  fontSize: "20px",
                }}
              >
                {item.title}
              </Link>
            ))}
          </Box>
        </Toolbar>
        {mobileOpen && (
          <Box
            component="nav"
            sx={{ position: "relative", zIndex: 10, width: "100%" }}
          >
            {drawer}
          </Box>
        )}
      </AppBar>
    </header>
  );
}

export default Header;
