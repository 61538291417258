import { Button, Grid, Link, Typography } from "@mui/material";
import React from "react";

export default function ServiceCard({ title, info, imgLeft, url, imgVid }) {
  return (
    <Grid
      container
      sx={{
        my: 10,
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Grid
        item
        lg={5}
        order={{ lg: imgLeft ? 1 : 2, xs: 2 }}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          ml: { lg: imgLeft ? "0px" : "60px", xs: "0px" },
        }}
      >
        <Typography
          variant="h3"
          sx={{
            mt: 4,
            fontSize: { lg: "56px", xs: "40px" },
            textAlign: "start",
            fontWeight: 500,
            mt: { lg: "0px", xs: "20px" },
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          {title}
        </Typography>
        <Typography variant="body1" sx={{ mt: 1, my: 4, textAlign: "justify" }}>
          {info}
        </Typography>
      </Grid>
      <Grid
        item
        lg={5}
        order={{ lg: imgLeft ? 2 : 1, xs: 1 }}
        sx={{
          ml: { lg: imgLeft ? "60px" : "0px", xs: "0px" },
        }}
      >
        <img
          src={imgVid}
          className="serviceCardImg"
          style={{
            objectFit: "cover",
            borderRadius: "20px",
          }}
        />
      </Grid>
    </Grid>
  );
}
