import { Box } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

function DashboardTopBar() {
  const navigate = useNavigate();
  return (
    <Box
      onClick={() => {
        localStorage.removeItem("admin greentooth");
        navigate("/dashboard-login");
      }}
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        background: "#fff",
        padding: "20px",
        color: "#1E3226",
        fontWeight: 500,
        cursor: "pointer",
      }}
    >
      Logout
    </Box>
  );
}

export default DashboardTopBar;
