import React from "react";
import Header from "../components/common/Header";
import Footer from "../components/common/Footer";
import BottomBar from "../components/common/BottomBar";
import FeedbacksSection from "../components/feedbacks/FeedbacksSection";

function FeedbackPage() {
  return (
    <main>
      <Header colorCh={true} />
      <FeedbacksSection />
      <Footer />
      <BottomBar />
    </main>
  );
}

export default FeedbackPage;
